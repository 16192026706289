<template>
  <div
    class="c-product-where-to-buy o-content-box u-flex u-flex-direction-column u-flex-align-center"
  >
    <h2 class="u-m--0 u-mb--s u-width-720 u-text-align--center">
      {{ model.heading }}
    </h2>
    <WhereToBuyStoreLocation
      :model="model"
      :selected-vendor="selectedVendor"
      @on-close="resetVendor"
      @on-location-search="updateVendors"
      @show-initial-vendors="showInitialVendors"
    />
    <div class="u-flex u-mb--m">
      <div class="u-mr--m">
        <Checkbox
          v-model="onlineType"
          :alternative="true"
          appearance="checkbox"
        />
        <span @click="onlineType = !onlineType">{{ model.onlineTypeText }}</span>
      </div>
      <div>
        <Checkbox
          v-model="physicalType"
          appearance="checkbox"
          :alternative="true"
        />
        <span @click="physicalType = !physicalType">{{ model.physicalTypeText }}</span>
      </div>
    </div>
    <transition-group
      ref="vendors"
      name="c-product-where__list"
      class="c-product-where-to-buy__vendors u-width-100 u-mt--s"
      :class="{
        'u-width-max': $mq == 'desktop'
      }"
    >
      <a
        v-for="vendor in filteredVendors"
        :key="vendor.name"
        :href="vendor.type == 'online' ? vendor.productLink : vendor.mainLink"
        rel="noopener"
        target="_blank"
        class="c-product-where-to-buy__vendor u-flex"
        :class="{
          'u-ml--xs u-mr--xs u-mb--s u-flex-shrink-0': $mq == 'desktop'
        }"
        @click="openPopup($event, vendor)"
      >
        <span
          v-if="vendor.shops && vendor.shops.length"
          class="c-product-where-to-buy__vendor-shop-amount u-font-size-small u-text-white"
          :class="{
            'u-font-size-xsmall': $mq != 'desktop'
          }"
        >
          {{ vendor.shops.length }}
          <template v-if="$mq == 'desktop'">

            {{ vendor.shops.length > 1 ? model.amountOfShopsPlural : model.amountOfShopsSingular }}
          </template>
        </span>
        <Picture
          :src="vendor.logo"
          resizer="ImageResizingNet"
          :alt="vendor.name"
          :desktop="[154, 65]"
          :mobile="[80, 30]"
          :options="{ mode: 'max' }"
        />
      </a>
    </transition-group>
    <div
      v-if="!filteredVendors || !filteredVendors.length"
      class="u-font-size-xlarge u-font-bold u-mt--l u-mb--l"
    >
      {{ model.emptyStateText }}
    </div>
    <div
      v-if="(model.link.url && model.link.text) || model.linkDescription"
      class="u-mt--m u-text-align--center u-font-size-medium"
    >
      <p
        v-if="model.linkDescription"
        class="u-mb--xs u-text-dark-gray-trans"
      >
        {{ model.linkDescription }}
      </p>
      <a
        v-if="model.link.url && model.link.text"
        class="u-text-dark-gray-trans u-font-size-medium"
        :href="model.link.url"
        :rel="model.link.openInNewWindow ? 'noopener' : null"
        :target="model.link.openInNewWindow ? '_blank' : null"
      >
        {{ model.link.text }}
      </a>
    </div>
  </div>
</template>

<script>

import Checkbox from '@/CVI/WebCore/components/Checkbox.vue'
import WhereToBuyStoreLocation from '@/CVI/Product/components/WhereToBuy/WhereToBuyStoreLocation.vue'
import tracker from '@/CVI/WebCore/core-tracker'

import viewPortDetect from '@/CVI/WebCore/lib/in-viewport-detect'
import Picture from '@/CVI/WebCore/components/Picture.vue'

export default {
  components: {
    Checkbox,
    WhereToBuyStoreLocation,
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      onlineType: true,
      physicalType: true,
      selectedVendor: null,
      hasSearchResults: false,
      initialVendors: [...this.model.vendors],
      vendors: [...this.model.vendors]
    }
  },
  computed: {
    filteredVendors() {
      const { onlineType, physicalType } = this

      return this.vendors.filter((vendor) => {
        if (physicalType && vendor.type == 'physical') {
          return true
        }
        if (onlineType && vendor.type == 'online') {
          return true
        }
        return false
      })
    }
  },
  watch: {
    onlineType(value) {
      tracker.track({
        event: 'whereToBuyFilter',
        whereToBuyFilterOption: `${this.model.onlineTypeText} ${value ? 'selected' : 'deselected'}`
      })
    },
    physicalType(value) {
      tracker.track({
        event: 'whereToBuyFilter',
        whereToBuyFilterOption: `${this.model.physicalTypeText} ${value ? 'selected' : 'deselected'}`
      })
    }
  },
  mounted() {
    this.trackVisibility()
  },
  methods: {
    openPopup(e, vendor) {
      const trackingObj = {
        event: 'whereToBuyChooseStore',
        whereToBuyStoreName: vendor.name
      }
      if (vendor.type == 'physical') {
        trackingObj.storeTypeChosen = `Physical store chosen - ${this.hasSearchResults ? 'location' : 'no location'}`
      } else {
        trackingObj.storeTypeChosen = 'Online store chosen'
      }
      tracker.track(trackingObj)

      if (this.hasSearchResults && vendor.type == 'physical') {
        e.preventDefault()
        this.selectedVendor = vendor
      }
    },
    resetVendor() {
      this.selectedVendor = null
    },
    updateVendors(vendors) {
      this.hasSearchResults = true
      this.vendors = vendors
    },
    showInitialVendors() {
      this.vendors = [...this.initialVendors]
      this.hasSearchResults = false
    },
    trackVisibility() {
      const vendors = this.$refs.vendors.$el
      const { productName } = this.model

      const observeOptions = {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: 1
      }
      viewPortDetect.inViewport(vendors, () => {
        tracker.track({
          event: 'whereToBuyStoreImpression',
          productName
        })
      }, observeOptions)
    }
  }
}
</script>

<style lang="scss">
@use '@/CVI/Product/scss/where-to-buy.scss';

</style>
