<template>
  <div class="u-flex u-flex-direction-column u-flex-align-center">
    <p
      class="u-m--0 u-mb--m"
      :class="{
        'u-text-align--center': $mq != 'desktop'
      }"
    >
      {{ descriptionText }}
    </p>
    <div class="c-product-where-to-buy__location-input u-mb--m u-flex u-flex-align-start">
      <div class="c-text-input-container">
        <div class="u-flex u-flex-direction-column">
          <input
            v-if="!city"
            ref="postcode"
            v-model.trim="$v.postalCode.$model"
            class="c-text-input c-product-where-to-buy__input"
            type="text"
            :class="{
              'c-text-input--error': inputError
            }"
            inputmode="numeric"
            :disabled="isLoading"
            :placeholder="model.postcodeText"
            :maxlength="model.postcodeLength"
            @keypress.enter="onPostCodeBlur"
            @blur="onPostCodeBlur"
          >
          <input
            v-if="city"
            v-model="city"
            class="c-text-input c-product-where-to-buy__input"
            type="text"
            @focus="clearCity"
          >
          <FormErrorLine
            v-if="inputError"
            class="u-mt--xs"
            :text="model.postcodeErrorText"
          />
        </div>
        <Loader
          v-if="isLoading"
          class="c-product-where-to-buy__loader"
          size="medium"
          color="medium-gray"
        />
      </div>
      <button
        class="c-product-where-to-buy__button c-button c-button--no-styles u-flex-shrink-0"
        @click="getUserLocation"
      >
        {{ model.nearMeButtonText }}
        <Icon
          class="u-ml--xs"
          :icon="iconLocation"
          :size="16"
          fill="black"
        />
      </button>
    </div>
    <Modal
      v-model="isModalVisible"
      title=""
    >
      <div v-if="selectedVendor">
        <div class="u-flex u-flex-direction-column u-text-align--center u-flex-align-center u-mb--m">
          <Picture
            :src="selectedVendor.logo"
            resizer="ImageResizingNet"
            :alt="selectedVendor.name"
            :desktop="[154, 65]"
            :mobile="[80, 30]"
            :options="{ mode: 'max' }"
          />
          <p
            class="u-m--0 u-mt--s"
            :class="{
              'u-width-70': $mq == 'desktop'
            }"
            v-html="text"
          />
        </div>
        <ul class="u-bare-list u-mb--m">
          <li
            v-for="shop in selectedVendor.shops"
            :key="shop.longitude"
            class="c-product-where-to-buy__shop u-flex u-flex-align-center"
          >
            <div class="u-flex u-flex-direction-column">
              <div class="u-flex u-flex-align-center u-mb--xxs">
                <strong>{{ selectedVendor.name }}</strong>
                <span
                  v-if="shop.distance"
                  class="c-product-where-to-buy__shop-distance u-ml--xs"
                >
                  {{ shop.distance }} {{ model.distanceUnit }}
                </span>
              </div>
              <template v-if="shop.city != shop.address">
                <span>{{ shop.city }} · {{ shop.address }}</span>
              </template>
              <template v-else>
                <span>{{ shop.address }}</span>
              </template>
            </div>
            <div class="c-product-where-to-buy__shop-map-link u-flex u-flex-align-center u-flex-shrink-0 u-ml--a u-mr--s">
              <a
                class="c-button c-button--small c-button--dark u-flex-shrink-0 u-ml--s"
                :href="`https://www.google.com/maps/search/${selectedVendor.name}+${shop.address}+${postalCode}+${shop.city}`"
                rel="noopener"
                target="_blank"
                @click="trackClick('find way')"
              >
                {{ model.popupFindWayLinkText }}
              </a>
            </div>
          </li>
        </ul>
        <div class="u-flex u-flex-justify-center u-mb--l">
          <a
            class="c-button c-button--light c-button--small"
            :href="selectedVendor.mainLink"
            rel="noopener"
            target="_blank"
            @click="trackClick('website')"
          >
            {{ model.popupWebsiteLinkText }}
          </a>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { numeric } from 'vuelidate/lib/validators'
import iconLocation from '@ds/svg/icons/bold/location-target-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import Modal from '@/CVI/WebCore/components/Modal.vue'
import api from '@/CVI/Product/lib/api'
import Picture from '@/CVI/WebCore/components/Picture.vue'
import FormErrorLine from '@/CVI/WebCore/components/FormErrorLine.vue'
import tracker from '@/CVI/WebCore/core-tracker'


export default {
  components: {
    Icon,
    Loader,
    Modal,
    Picture,
    FormErrorLine
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    selectedVendor: {
      type: Object,
      default: null
    }
  },
  validations: {
    postalCode: {
      numeric
    }
  },
  data() {
    return {
      iconLocation,
      hasSearchResults: true,
      isModalVisible: false,
      isLoading: false,
      postalCode: null,
      city: '',
      userLat: '',
      userLong: '',
      locationCallInProgress: false
    }
  },
  computed: {
    descriptionText() {
      return this.model.description.replace('{0}', this.model.productName)
    },
    text() {
      return this.model.popupHeading.replace('{0}', this.selectedVendor.name)
        .replace('{1}', this.city)
        .replace('{2}', this.model.productName)
    },
    inputError() {
      return (this.$v.postalCode.$dirty && this.$v.postalCode.$invalid)
    }
  },
  watch: {
    selectedVendor(vendor) {
      if (vendor) {
        this.isModalVisible = true
      }
    },
    isModalVisible(value) {
      if (!value) {
        this.$emit('on-close')
        tracker.track({
          event: 'whereToBuyPopUpDismissed',
          whereToBuyStoreName: this.selectedVendor.name
        })
      } else {
        tracker.track({
          event: 'whereToBuyPopUpImpression',
          whereToBuyStoreName: this.selectedVendor.name
        })
      }
    },
    async postalCode(postcode) {
      if (postcode && postcode.length == this.model.postcodeLength && !this.$v.postalCode.$invalid && !this.locationCallInProgress) {
        this.isLoading = true
        await this.getVendorsByPostalCode({ postcode })
        const { city } = this
        tracker.track({
          event: 'whereToBuyPostalCode',
          postalValue: city || parseInt(postcode, 10)
        })
      }
    }
  },
  methods: {
    getUserLocation() {
      const geoSuccess = async ({
        coords: { latitude, longitude }
      }) => {
        this.postalCode = null
        tracker.track({
          event: 'whereToBuyNearMe',
          nearMeConsentStatus: 'Yes'
        })
        await this.getVendorsByPostalCode({ latitude, longitude })
        this.isLoading = false
      }
      const geoError = () => {
        tracker.track({
          event: 'whereToBuyNearMe',
          nearMeConsentStatus: 'No'
        })
        this.isLoading = false
      }
      this.isLoading = true
      navigator.geolocation.getCurrentPosition(geoSuccess, geoError)
    },
    async getVendorsByPostalCode({ postcode, latitude, longitude }) {
      this.isLoading = true
      const { ean } = this.model
      if (ean) {
        try {
          const { data } = await api.vendors.get({
            postcode, latitude, longitude, ean
          })
          const { city, vendors } = data
          this.city = city
          this.locationCallInProgress = true
          this.postalCode = data.postcode
          this.$nextTick(() => {
            this.locationCallInProgress = false
          })
          if (vendors && vendors.length) {
            this.$emit('on-location-search', vendors)
          }
        } catch (e) {
          this.$emit('on-location-search', [])
        }
        this.isLoading = false
      }
    },
    clearCity() {
      this.city = ''
      this.$nextTick(() => {
        this.$refs.postcode.focus()
      })
    },
    onPostCodeBlur() {
      if (!this.postalCode) {
        this.$emit('show-initial-vendors')
      }
    },
    trackClick(link) {
      tracker.track({
        event: 'whereToBuyPopUpLinkClick',
        whereToBuyPopUpLinkName: `Pop-up click ${link}`,
        whereToBuyStoreName: this.selectedVendor.name
      })
    }
  }
}
</script>
