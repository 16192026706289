import axios from 'axios'

const language = document.documentElement.lang

export default {
  productCards: {
    baseUrl: `/cvi/products/api/${language}/cards`,
    get({
      take, skip, categoryIds, brandIds
    }) {
      return axios.post(this.baseUrl, {
        take, skip, categoryIds, brandIds
      })
    }
  },
  vendors: {
    baseUrl: `/cvi/products/api/${language}/vendors`,
    get({
      ean, postcode, latitude, longitude, cancelToken
    }) {
      return axios.get(`${this.baseUrl}`, {
        params: {
          postcode, latitude, longitude, ean
        }
      }, {
        cancelToken: cancelToken?.token
      })
    }
  }
}
